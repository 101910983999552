<template>
  <b-row
    align-v="center"
    class="h-100 justify-content-center text-white"
  >

    <b-col
      cols="10"
      md="6"
    >
      <div class="text-center mb-2">
        <h1 class="font-weight-bolder text-white">
          {{ showData.name }}贊助系統
        </h1>
      </div>

      <div class="text-center mb-2">
        <h2 class="font-weight-bolder text-white">
          自助贊助中心, 快速金流繳費, 保障安全隱私
        </h2>
      </div>

      <hr>

      <div class="text-center mb-2">
        <h4 class="font-weight-bolder text-white">
          遊戲伺服器：【{{ showData.name }}】
        </h4>
      </div>

      <b-form-input
        v-model="orderData.account"
        placeholder="請輸入遊戲帳號"
        class="mb-2 form-control"
      />

      <cleave
        v-model="orderData.money"
        :options="{ numeral: true, numeralThousandsGroupStyle: 'no' }"
        placeholder="請輸入繳款金額"
        class="mb-1 form-control"
      />

      <div class="row mb-50">
        <b-form-group
          label="目前幣值"
          class="col-6"
        >
          <b-form-input
            disabled
            placeholder="...等待計算"
            :value="pointRatio.ratio && `1 : ${pointRatio.ratio}`"
          />
        </b-form-group>

        <b-form-group
          label="換算金額"
          class="col-6"
        >
          <b-form-input
            disabled
            placeholder="...等待計算"
            :value="pointRatio.total"
          />
        </b-form-group>
      </div>

      <b-form-select
        v-model="orderData.payment"
        :options="showData.payments || []"
        value-field="id"
        text-field="title"
        class="mb-2 form-control"
      >
        <template #first>
          <b-form-select-option
            disabled
            :value="null"
          >
            請選擇付款方式
          </b-form-select-option>
        </template>
      </b-form-select>
      
      <b-form-input
        v-if="paymentData.type.includes('gomypay')"
        v-model="orderData.buyer_name"
        placeholder="購買者姓名"
        class="mb-2 form-control"
      />
      
      <b-form-input
        v-if="paymentData.type.includes('gomypay')"
        v-model="orderData.buyer_phone"
        placeholder="購買者手機"
        class="mb-2 form-control"
      />
      
      <b-form-input
        v-if="paymentData.type.includes('gomypay')"
        v-model="orderData.buyer_email"
        placeholder="購買者信箱"
        class="mb-2 form-control"
      />

      <div
        v-if="firstReserveRatio"
        class="text-center mb-2"
      >
        <h1 class="font-weight-bolder text-white">
          首儲比例 1 : {{ firstReserveRatio }}
        </h1>
      </div>

      <div class="text-center">
        <b-overlay
          :show="showLoading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
          class="mt-1"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
            @click="$emit('on-submit', orderData)"
          >
            確定贊助
          </b-button>
        </b-overlay>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import {
  BOverlay,
  BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BOverlay,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,

    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    showLoading: {
      type: Boolean,
      required: true,
    },
    showData: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props) {
    const { payments } = props.showData
     
    const orderData = ref({
      money: Math.min(...payments.map(m => m.min_amount)) || 300,
      // pt: 'ecpay_cvs',
      payment: (payments.find(() => true) && payments.find(() => true).id) || null,
    })

    const paymentData = computed(() => payments.find(f => f.id === orderData.value?.payment))
    
    const pointRatio = computed(() => {
      const { currencies } = props.showData
      const { money } = orderData.value

      if (currencies && money) {
        const currencie = currencies.find(f => parseInt(money, 0) >= f.min_amount && parseInt(money, 0) <= f.max_amount)
        const ratio = parseFloat((currencie && currencie.ratio) || 1)
        const total = Math.round(parseInt(money, 0) * ratio)
        return { ratio, total }
      }
      return { ratio: null, total: null }
    })

    const firstReserveRatio = computed(() => {
      const { currencies } = props.showData

      if (currencies) {
        const currencie = currencies.find(f => f.min_amount === 0 && f.max_amount === 0)
        return currencie && currencie.ratio
      }
      return null
    })

    return {
      orderData,

      paymentData,

      pointRatio,

      firstReserveRatio,
    }
  },
}
</script>

<style lang="scss">
.form-control {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:disabled {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7) !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
  }
}

label {
  color: #d0d2d6;
}

.custom-select {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(255, 255, 255, 0.3);

  &:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
    border-color: rgba(255, 255, 255, 0.3);
  }
}
</style>
